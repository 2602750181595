import axios from 'axios';

const Api = () => {
  const getDocNumberInfoFetch = (docType: string, docNumber: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_GET_DOCNUMBER_INFO_URL}`, {
      params: {
        document_type: docType,
        document_value: docNumber
      }
    });

  const confirmationFetch = (checkout: object): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_CHECKOUT_URL}orders`, checkout);

  const getPaymentUrl = (orderId: number | string) =>
    axios.get(`${process.env.REACT_APP_CHECKOUT_URL}orders/${orderId}`);

  return { getDocNumberInfoFetch, confirmationFetch, getPaymentUrl };
};

export default Api;
