import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import { Analytics } from '@wdynamo/common/lib/services';

import { Store } from './store';

import { App } from './App';

const Root = () => {
  const analytics = Analytics();

  useEffect(() => {
    analytics.initializeReactGA(process.env.REACT_APP_GA, process.env.REACT_APP_GA_DEBUG === 'true');
  }, []);

  return (
    <Provider store={Store}>
      <App />
    </Provider>
  );
};

export default Root;
